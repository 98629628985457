// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Login$Weblab from "../components/Login.bs.js";
import * as Theme$Weblab from "../service/Theme.bs.js";
import * as ReactHelmet from "react-helmet";
import * as Topbar$Weblab from "../components/Topbar.bs.js";
import * as Session$Weblab from "../service/Session.bs.js";
import * as Sidebar$Weblab from "../components/Sidebar.bs.js";
import * as Sidepane$Weblab from "../components/sidepane/Sidepane.bs.js";
import * as CloudBase$Weblab from "./CloudBase.bs.js";
import * as Core from "@material-ui/core";
import * as Belt_HashMapString from "rescript/lib/es6/belt_HashMapString.js";
import * as SidebarsBase$Weblab from "../components/SidebarsBase.bs.js";
import * as SupabaseClient$Weblab from "../bindings/supabase/SupabaseClient.bs.js";
import * as Styles from "@material-ui/core/styles";
import * as Sidepane_Settings$Weblab from "../components/sidepane/Sidepane_Settings.bs.js";
import Settings from "@material-ui/icons/Settings";
import Weblab_logoSvg from "/src/assets/svg/weblab_logo.svg";

var useStyles = Styles.makeStyles(function (_theme) {
      return {
              heading: {
                padding: "16px"
              },
              grid: {
                display: "grid",
                padding: "24px",
                gridGap: "24px",
                gridTemplateColumns: "1fr 1fr 1fr 1fr"
              }
            };
    });

var Styles$1 = {
  useStyles: useStyles
};

function Cloud(Props) {
  var pageContext = Props.pageContext;
  var match = React.useState(function () {
        
      });
  var setSession = match[1];
  var session = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoginDialog = match$1[1];
  var match$2 = React.useState(function () {
        return "0";
      });
  var setActiveTab = match$2[1];
  var activeTab = match$2[0];
  var toggle_active_tab = React.useCallback((function (tab) {
          if (activeTab !== tab) {
            return Curry._1(setActiveTab, (function (_x) {
                          return tab;
                        }));
          }
          
        }), [
        activeTab,
        setActiveTab
      ]);
  var match$3 = React.useState(function () {
        return false;
      });
  var setDarkMode = match$3[1];
  var darkMode = match$3[0];
  var globClasses = Curry._1(Theme$Weblab.Styles.useStyles, undefined);
  Curry._1(useStyles, undefined);
  React.useEffect((function () {
          Curry._1(setSession, (function (param) {
                  return Caml_option.nullable_to_opt(SupabaseClient$Weblab.supabase.auth.session());
                }));
          SupabaseClient$Weblab.supabase.auth.onAuthStateChange(function (param, session) {
                return Curry._1(setSession, (function (param) {
                              if (session == null) {
                                return ;
                              } else {
                                return Caml_option.some(session);
                              }
                            }));
              });
          Curry._1(setDarkMode, (function (param) {
                  return Theme$Weblab.initializeDarkMode(undefined);
                }));
          
        }), []);
  var theme = React.useMemo((function () {
          return Styles.createTheme(Theme$Weblab.getThemeProto(darkMode));
        }), [darkMode]);
  var topbar = React.useMemo((function () {
          return React.createElement(Topbar$Weblab.make, {
                      setLoginDialog: setLoginDialog
                    });
        }), []);
  var sidebar = React.useMemo((function () {
          return React.createElement(Sidebar$Weblab.make, {
                      toggle_sidebar: SidebarsBase$Weblab.toggle_sidebar,
                      activeTab: activeTab,
                      toggle_active_tab: toggle_active_tab,
                      children: React.createElement(Core.Tooltip, {
                            children: React.createElement(Settings, {}),
                            title: "Settings"
                          })
                    });
        }), [
        activeTab,
        toggle_active_tab
      ]);
  var sidepane = React.useMemo((function () {
          return React.createElement(Sidepane$Weblab.make, {
                      activeTab: activeTab,
                      children: React.createElement(Sidepane_Settings$Weblab.make, {
                            toggle_sidebar: SidebarsBase$Weblab.toggle_sidebar,
                            darkMode: darkMode,
                            setDarkMode: setDarkMode
                          })
                    });
        }), [
        activeTab,
        darkMode
      ]);
  var productData = Belt_HashMapString.fromArray(pageContext.productData.map(function (x) {
            return [
                    x.id,
                    x
                  ];
          }));
  return React.createElement(Styles.ThemeProvider, {
              children: React.createElement(Session$Weblab.SessionContext.Provider.make, {
                    value: session,
                    children: null
                  }, React.createElement(Core.CssBaseline, {}), React.createElement(ReactHelmet.Helmet, {
                        children: null
                      }, React.createElement("link", {
                            href: "/favicon.png",
                            rel: "icon",
                            type: "image/png"
                          }), React.createElement("title", undefined, "Weblab NotebookHub")), React.createElement(Core.Box, {
                        className: globClasses.sidebar,
                        children: React.createElement("nav", undefined, sidebar),
                        bgcolor: theme.palette.background.default,
                        boxShadow: 2
                      }), React.createElement(Core.Box, {
                        className: globClasses.wrapper,
                        children: null,
                        gridTemplateColumns: {
                          xs: "48px 0px 1fr",
                          sm: "48px 0px 1fr",
                          md: "48px 0px 1fr"
                        },
                        id: "wrapper"
                      }, React.createElement(Core.Box, {
                            className: globClasses.topbar,
                            children: topbar,
                            boxShadow: 2
                          }), React.createElement(Core.Box, {
                            className: globClasses.sidepane,
                            children: sidepane,
                            bgcolor: theme.palette.background.default,
                            boxShadow: 3
                          }), React.createElement("main", undefined, React.createElement(Core.Box, {
                                style: {
                                  bottom: "0",
                                  left: "0",
                                  position: "absolute",
                                  right: "0",
                                  top: "0"
                                },
                                children: null,
                                height: "40%",
                                margin: "auto",
                                width: "40%"
                              }, React.createElement(Core.Typography, {
                                    align: "center",
                                    children: null,
                                    color: "primary",
                                    variant: "h3",
                                    style: {
                                      fontWeight: "700",
                                      marginBottom: "32px"
                                    }
                                  }, React.createElement(Core.Box, {
                                        clone: true,
                                        children: React.createElement(Weblab_logoSvg, {}),
                                        height: 64,
                                        width: 64
                                      }), "Web", React.createElement(Core.Box, {
                                        style: {
                                          color: theme.palette.secondary.main
                                        },
                                        children: "lab",
                                        display: "inline"
                                      })), session !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Core.Box, {
                                          children: Belt_Array.map(pageContext.priceData, (function (price) {
                                                  var product = Belt_HashMapString.get(productData, price.product);
                                                  if (product !== undefined) {
                                                    return React.createElement(Core.Card, {
                                                                children: null
                                                              }, React.createElement(Core.CardHeader, {
                                                                    title: product.name
                                                                  }), React.createElement(Core.CardContent, {
                                                                    children: null
                                                                  }, React.createElement(Core.Typography, {
                                                                        children: product.description
                                                                      }), React.createElement(Core.Typography, {
                                                                        children: String(price.unit_amount / 100 | 0) + " " + price.currency + "/month"
                                                                      })), React.createElement(Core.CardActions, {
                                                                    children: React.createElement(Core.Button, {
                                                                          onClick: (function (param) {
                                                                              return CloudBase$Weblab.processSubscription(session);
                                                                            }),
                                                                          children: "Subscribe",
                                                                          variant: "contained"
                                                                        })
                                                                  }));
                                                  } else {
                                                    return null;
                                                  }
                                                })),
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center"
                                        })) : null))), React.createElement(Login$Weblab.make, {
                        loginDialog: match$1[0],
                        setLoginDialog: setLoginDialog
                      })),
              theme: theme
            });
}

Cloud.displayName = "Cloud";

var make = Cloud;

var $$default = Cloud;

export {
  Styles$1 as Styles,
  make ,
  $$default ,
  $$default as default,
  
}
/* useStyles Not a pure module */
