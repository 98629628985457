// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Fetch from "bs-fetch/lib/es6/src/Fetch.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Errors$Weblab from "../service/Errors.bs.js";
import * as StripeJs from "@stripe/stripe-js";

var stripeJsProm = StripeJs.loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

function processSubscription(session) {
  var idProm = fetch("https://us-central1-scenic-treat-317309.cloudfunctions.net/subscription-test", Fetch.RequestInit.make(/* Post */2, {
                  "Content-Type": "application/json"
                }, Caml_option.some(Belt_Option.getWithDefault(JSON.stringify({
                              access_token: session.access_token
                            }), "")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
          return prim.json();
        }).then(function (response) {
        var dict = Js_json.classify(response);
        if (typeof dict === "number") {
          return Promise.reject(Errors$Weblab.toExn({
                          RE_EXN_ID: Errors$Weblab.Message,
                          _1: "Error: The response from starting the checkout session didn't containt a JSON Object."
                        }));
        }
        if (dict.TAG !== /* JSONObject */2) {
          return Promise.reject(Errors$Weblab.toExn({
                          RE_EXN_ID: Errors$Weblab.Message,
                          _1: "Error: The response from starting the checkout session didn't containt a JSON Object."
                        }));
        }
        var jSONid = Js_dict.get(dict._0, "id");
        if (jSONid === undefined) {
          return Promise.reject(Errors$Weblab.toExn({
                          RE_EXN_ID: Errors$Weblab.Message,
                          _1: "Error: The response from starting the checkout session doesn't contain an \"id\" field"
                        }));
        }
        var id = Js_json.classify(Caml_option.valFromOption(jSONid));
        if (typeof id === "number" || id.TAG !== /* JSONString */0) {
          return Promise.reject(Errors$Weblab.toExn({
                          RE_EXN_ID: Errors$Weblab.Message,
                          _1: "Error: The checkout session \"id\" is not a string"
                        }));
        } else {
          return Promise.resolve(id._0);
        }
      });
  Promise.all([
            stripeJsProm,
            idProm
          ]).then(function (tuple) {
          var stripeJs = tuple[0];
          var id = tuple[1];
          return stripeJs.redirectToCheckout({
                      sessionId: id
                    });
        }).catch(function (error) {
        Errors$Weblab.alertError({
              TAG: /* Error */1,
              _0: Errors$Weblab.fromPromiseError(error)
            });
        return Promise.resolve(undefined);
      });
  
}

export {
  stripeJsProm ,
  processSubscription ,
  
}
/* stripeJsProm Not a pure module */
